var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._m(0),_c('div',[_c('div',{staticClass:"container-fluid p-0"},[_c('div',{staticClass:"row no-gutters"},[_c('div',{staticClass:"col-lg-4"},[_c('div',{staticClass:"authentication-page-content p-4 d-flex align-items-center min-vh-100"},[_c('div',{staticClass:"w-100"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-lg-9"},[_c('div',[_vm._m(1),_c('div',{staticClass:"p-2 mt-5"},[_c('form',{staticClass:"form-horizontal"},[_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_c('div',{staticClass:"mt-4 text-center"},[_c('router-link',{staticClass:"text-muted",attrs:{"tag":"a","to":"/auth/recoverpwd-1"}},[_c('i',{staticClass:"mdi mdi-lock mr-1"}),_vm._v(" Forgot your password? ")])],1)])]),_c('div',{staticClass:"mt-5 text-center"},[_c('p',[_vm._v(" Don't have an account ? "),_c('router-link',{staticClass:"font-weight-medium text-primary",attrs:{"tag":"a","to":"/auth/register-1"}},[_vm._v("Register")])],1),_vm._m(6)])])])])])])]),_vm._m(7)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home-btn d-none d-sm-block"},[_c('a',{attrs:{"href":"/"}},[_c('i',{staticClass:"mdi mdi-home-variant h2 text-white"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-center"},[_c('div',[_c('a',{staticClass:"logo",attrs:{"href":"index.html"}},[_c('img',{attrs:{"src":require("@/assets/images/logo-dark.png"),"height":"20","alt":"logo"}})])]),_c('h4',{staticClass:"font-size-18 mt-4"},[_vm._v("Welcome Back !")]),_c('p',{staticClass:"text-muted"},[_vm._v("Sign in to continue to Nazox.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group auth-form-group-custom mb-4"},[_c('i',{staticClass:"ri-user-2-line auti-custom-input-icon"}),_c('label',{attrs:{"for":"username"}},[_vm._v("Username")]),_c('input',{staticClass:"form-control",attrs:{"type":"text","id":"username","placeholder":"Enter username"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group auth-form-group-custom mb-4"},[_c('i',{staticClass:"ri-lock-2-line auti-custom-input-icon"}),_c('label',{attrs:{"for":"userpassword"}},[_vm._v("Password")]),_c('input',{staticClass:"form-control",attrs:{"type":"password","id":"userpassword","placeholder":"Enter password"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"custom-control custom-checkbox"},[_c('input',{staticClass:"custom-control-input",attrs:{"type":"checkbox","id":"customControlInline"}}),_c('label',{staticClass:"custom-control-label",attrs:{"for":"customControlInline"}},[_vm._v("Remember me")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-4 text-center"},[_c('button',{staticClass:"btn btn-primary w-md waves-effect waves-light",attrs:{"type":"submit"}},[_vm._v("Log In")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v(" © 2020 Nazox. Crafted with "),_c('i',{staticClass:"mdi mdi-heart text-danger"}),_vm._v(" by Themesdesign ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-lg-8"},[_c('div',{staticClass:"authentication-bg"},[_c('div',{staticClass:"bg-overlay"})])])
}]

export { render, staticRenderFns }